<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="80%"
            :center="true"
            top="1vh"
            :close-on-click-modal="false"
            :before-close="() => closeDetryBillDialog('DetryBillEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="DetryBill.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="DetryBill.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="DetryBill.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="DetryBill.state===3" type="danger" size="mini">已驳回</el-tag>
            </template>
            <el-form
                :model="DetryBill"
                :rules="rules"
                ref="DetryBillEditForm"
                label-width="100px"
                size="small"
                label-position="top"
            >
                <div v-if="show_type">
                    <fieldset class="fieldsetc">
                        <legend class="legendc">基础信息</legend>
                        <el-row :gutter="20">
                            <el-col :span="9">
                                <el-form-item label="客户" prop="client_id">
                                    <el-select
                                        v-model="DetryBill.client_id"
                                        filterable
                                        placeholder="选择客户"
                                        class="clientc"
                                        clearable
                                        size="small"
                                        :disabled="DetryBill.detry_product.length>0"
                                    >
                                        <el-option
                                            v-for="client in AllClient"
                                            :key="client.id"
                                            :label="client.name"
                                            :value="client.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label="集装箱号">
                                    <el-input v-model="DetryBill.box_num" size="small" clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="5">
                                <el-form-item label="车牌号">
                                    <el-input v-model="DetryBill.car_num" size="small" clearable></el-input>
                                </el-form-item>
                            </el-col >
                            <el-col :span="5">
                                <el-form-item label="司机手机" prop="driver_phone">
                                    <el-input v-model="DetryBill.driver_phone" size="small" clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </fieldset>
                    <div v-if="DetryBill.detry_product.length>0">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">产品信息</legend>
                            <common-table-component
                                details_auth="出库单中产品详情"
                                table_size="mini"
                                table_height="300px"
                                :col_arr="fcol_arr"
                                :table_data="DetryBill.detry_product"
                                @details_row="show_detry"
                            ></common-table-component>
                            <edit-detry-stock-component
                                :fdetry_stock_data="fdetry_stock_data"
                                :detryDialogVisible="detryDialogVisible"
                                :fAllClientStockRate="fAllClientStockRate"
                                @exitDetryDialog="exitDetryDialog"
                                @refresh="$emit('frefresh')"
                            ></edit-detry-stock-component>
                            <el-card>
                                <el-row :gutter="20">
                                    <el-col :span="6">合计应收费用：<span class="collect_moneyc">{{this.collect_money | diy_money}}</span></el-col>
                                    <el-col :span="6">合计应付费用：<span class="pay_moneyc">{{this.pay_money | diy_money}}</span></el-col>
                                    <el-col :span="6">合计出库重量：<span class="detry_weightc">{{this.detry_weight | diy_weight}}</span></el-col>
                                    <el-col :span="6">合计出库托盘数：<span class="detry_weightc">{{this.detry_tray_num | diy_tray_num}}</span></el-col>
                                </el-row>
                            </el-card>
                        </fieldset>
                    </div>
                    <fieldset>
                        <legend class="legendc">其他信息</legend>
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="仓管员" prop="warehouse_staff_id">
                                    <el-select
                                        v-model="DetryBill.warehouse_staff_id"
                                        filterable
                                        placeholder="选择仓管员"
                                        clearable
                                        size="small"
                                        class="clientc"
                                    >
                                        <el-option
                                            v-for="staff in fAllSatff"
                                            :key="staff.id"
                                            :label="staff.name"
                                            :value="staff.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="录单员" prop="entry_staff_id">
                                    <el-select
                                        v-model="DetryBill.entry_staff_id"
                                        filterable
                                        placeholder="选择录单员"
                                        clearable
                                        size="small"
                                        class="clientc"
                                    >
                                        <el-option
                                            v-for="staff in fAllSatff"
                                            :key="staff.id"
                                            :label="staff.name"
                                            :value="staff.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="出库日期" prop="detry_date">
                                    <el-date-picker
                                    v-model="DetryBill.detry_date"
                                        align="right"
                                        type="date"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd"
                                        class="detry_date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </fieldset>
                </div>
                <div v-else>
                    <fieldset class="fieldsetc">
                        <legend class="legendc">基础信息</legend>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>客户名称</span>
                                </template>
                                <span>{{DetryBill.client_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>集装箱号</span>
                                </template>
                                <span>{{DetryBill.box_num}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>车牌号</span>
                                </template>
                                <span>{{DetryBill.car_num}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>司机手机</span>
                                </template>
                                <span>{{DetryBill.driver_phone}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </fieldset>
                    <div v-if="DetryBill.detry_product.length>0">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">产品信息</legend>
                            <common-table-component
                                table_size="mini"
                                table_height="300px"
                                :col_arr="fcol_arr"
                                :table_data="DetryBill.detry_product"
                            ></common-table-component>
                            <el-card>
                                <el-descriptions
                                    class="margin-top"
                                    :column="4"
                                    :labelStyle="{'width':'120px','text-align':'center'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                >
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#00BB00'}">
                                        <template slot="label">
                                            <span>合计应收费用</span>
                                        </template>
                                        <span>{{this.collect_money | diy_money}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                        <template slot="label">
                                            <span>合计应付费用</span>
                                        </template>
                                        <span>{{this.pay_money | diy_money}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                        <template slot="label">
                                            <span>合计出库重量</span>
                                        </template>
                                        <span>{{this.detry_weight | diy_weight}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :contentStyle="{'text-align':'center','color':'#FF0000'}">
                                        <template slot="label">
                                            <span>合计出库托盘数</span>
                                        </template>
                                        <span>{{this.detry_tray_num | diy_tray_num}}</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-card>
                        </fieldset>
                    </div>
                    <fieldset>
                        <legend class="legendc">其他信息</legend>
                        <el-descriptions
                            class="margin-top"
                            :column="3"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>仓管员</span>
                                </template>
                                <span>{{DetryBill.warehouse_staff_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>录单员</span>
                                </template>
                                <span>{{DetryBill.entry_staff_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>出库日期</span>
                                </template>
                                <span>{{DetryBill.detry_date}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </fieldset>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="to_approval_auth"
                        type="success"
                        plain
                        @click="to_approval('DetryBillEditForm')"
                        >提交</el-button
                    >
                    <el-button
                        v-if="sel_approval_auth"
                        type="warning"
                        plain
                        @click="sel_approval()"
                        >查看审批</el-button
                    >
                    <el-button
                        v-if="withdraw_auth"
                        type="danger"
                        plain
                        @click="DetryBillWithdraw('DetryBillEditForm')"
                        >审批撤回</el-button
                    >
                    <el-button
                        v-if="edit_auth"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="del_auth && (DetryBill.detry_product.length===0)"
                        type="danger"
                        plain
                        @click="DetryBillDel('DetryBillEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeDetryBillDialog('DetryBillEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('DetryBillEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('DetryBillEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDetryBillDialog('DetryBillEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('DetryBillEditForm')"
                        >确定修改</el-button
                    >
                    <el-button plain @click="closeDetryBillDialog('DetryBillEditForm')"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="DetryBill.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="DeterBillApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'
import EditDetryStockComponent from './EditDetryStockComponent'

import {
    detry_bill_add_request,
    detry_bill_del_request,
    detry_bill_edit_request,
    detry_bill_to_approval_request,
    detry_bill_sel_approval_request,
    detry_bill_approval_request,
    detry_bill_details_product_request,
    detry_bill_withdraw_request
} from '@/network/WareHouse/DetryBill.js'

import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'
import { client_stock_rate_list_request} from '@/network/list.js'

import CommonTableComponent from '@/components/common/CommonTableComponent'

export default {
    name:'DetryBillEditComponent',
    data(){
        return {
            login_loading: false,
            DetryBill: {
                client_id: '',
                box_num: '',
                car_num: '',
                driver_phone: '',
                warehouse_staff_id: '',
                entry_staff_id: this.$store.state.user.id,
                detry_date: '',
                detry_product: []
            },
            rules: {
                client_id: [
                    {
                        required: true,
                        message: '请选客户',
                        trigger: 'blur',
                    },
                ],
                driver_phone: [
                    {
                        pattern: /^1[3,5,6,7,8,9]\d{9}$/,
                        message: '手机号格式不正确',
                        trigger: 'blur',
                    },
                ],
                warehouse_staff_id: [
                    {
                        required: true,
                        message: ' 请选择仓管员',
                        trigger: 'blur',
                    },
                ],
                entry_staff_id: [
                    {
                        required: true,
                        message: ' 请选择录单员',
                        trigger: 'blur',
                    },
                ],
                detry_date: [
                    {
                        required: true,
                        message: ' 请选择出库日期',
                        trigger: 'blur',
                    },
                ]
            },
            fcol_arr: [
                {
                    prop: 'with_entry_bill_name',
                    label: '关联入库单',
                    minWidth: '120px'
                },
                {
                    prop: 'product_name',
                    label: '产品名称',
                    minWidth: '220px'
                },{
                    prop: 'code',
                    label: '产品型号',
                    minWidth: '150px'
                },{
                    prop: 'batch_number',
                    label: '订单号',
                    minWidth: '120px'
                },{
                    prop: 'specifications',
                    label: '规格',
                    minWidth: '100px'
                },{
                    prop: 'weight',
                    label: '出库重量(KG)',
                    minWidth: '120px',
                    editdata(row) {
                        return thousandBitSeparator(row['weight'])
                    }
                },{
                    prop: 'tray_num',
                    label: '出库托盘数(个)',
                    minWidth: '120px'
                },{
                    prop: 'labour_money_rate_name',
                    label: '应收装车费率',
                    minWidth: '100px',
                    html(row) {
                        return '<span style="color:#00BB00;">' + row['labour_money_rate_name'] + '</span>'
                    }
                },{
                    prop: 'labour_money',
                    label: '应收装车费(元)',
                    minWidth: '120px',
                    html(row) {
                        return '<span style="color:#00BB00;">' + thousandBitSeparator(fomatFloat(row['labour_money'])) + '</span>'
                    }
                },{
                    prop: 'pay_labour_money_rate_name',
                    label: '应付装车费率',
                    minWidth: '100px',
                    html(row) {
                        return '<span style="color:#FF0000;">' + row['pay_labour_money_rate_name'] + '</span>'
                    }
                },{
                    prop: 'pay_labour_money',
                    label: '应付装车费(元)',
                    minWidth: '120px',
                    html(row) {
                        return '<span style="color:#FF0000;">' + thousandBitSeparator(fomatFloat(row['pay_labour_money'])) + '</span>'
                    }
                },{
                    prop: 'other_money',
                    label: '应收其他费用(元)',
                    minWidth: '130px',
                    html(row) {
                        return '<span style="color:#00BB00;">' + thousandBitSeparator(fomatFloat(row['other_money'])) + '</span>'
                    }
                },{
                    prop: 'pay_money',
                    label: '应付其它费用(元)',
                    minWidth: '130px',
                    html(row) {
                        return '<span style="color:#FF0000;">' + thousandBitSeparator(fomatFloat(row['pay_money'])) + '</span>'
                    }
                },{
                    prop: 'bill_num',
                    label: '提单号',
                    minWidth: '130px',
                },{
                    prop: 'localtion_name',
                    label: '所在库位',
                    minWidth: '80px',
                },{
                    prop: 'storeroom_name',
                    label: '所在仓库',
                    minWidth: '120px',
                },{
                    prop: 'enterprise_name',
                    label: '所在公司',
                    minWidth: '260px',
                },{
                    prop: 'note',
                    label: '备注',
                    minWidth: '200px',
                    show_overflow_tooltip: true
                }
            ],
            ApprovalData: [],
            ApprovalDialogVisible: false,
            DeterBillApprovalRequest:detry_bill_approval_request,
            fdetry_stock_data: {},
            detryDialogVisible: false,
            fAllClientStockRate: []
        }
    },
    computed:{
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.DetryBill.name ? '出库单：' + this.DetryBill.name : '出库单详情'
            } else if (this.show_type === 1) {
                return '添加出库单'
            } else {
                return '修改出库单'
            }
        },
        collect_money() {
            const a = this.DetryBill.detry_product.reduce( (s,cur) => {
                return s + cur.labour_money * 1
            },0)
            const b= this.DetryBill.detry_product.reduce( (s,cur) => {
                return s + cur.other_money * 1
            },0)

            return a + b
        },
        pay_money() {
            return this.DetryBill.detry_product.reduce( (s,cur) => {
                return s + cur.pay_money * 1 + cur.pay_labour_money * 1
            },0)
        },
        detry_weight() {
            return this.DetryBill.detry_product.reduce( (s,cur) => {
                return s + cur.weight * 1
            },0)
        },
        detry_tray_num() {
            return this.DetryBill.detry_product.reduce( (s,cur) => {
                return s + cur.tray_num * 1
            },0)
        }
    },
    methods:{
        closeDetryBillDialog() {
            this.reset_data()
            this.$emit('exitDetryBillDialog')
        },
        resetForm() {
            this.reset_data()
        },
        reset_data() {
            this.DetryBill = {
                client_id: '',
                box_num: '',
                car_num: '',
                driver_phone: '',
                warehouse_staff_id: '',
                entry_staff_id: this.$store.state.user.id,
                detry_date: '',
                detry_product: []
            }
        },
        DetryBillDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    detry_bill_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDetryBillDialog(formName))
                })
                .catch((err) => {})
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.DetryBillInsert(formName)
            })
        },
        DetryBillInsert(formName) {
            this.login_loading = true
            detry_bill_add_request(this.DetryBill)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitDetryBillDialog')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
                .finally(() => this.closeDetryBillDialog(formName))
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.DetryBillEdit(formName)
            })
        },
        DetryBillEdit(formName) {
            this.login_loading = true
            this.DetryBill.id = this.id
            detry_bill_edit_request(this.DetryBill)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitDetryBillDialog')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
                .finally(() => this.closeDetryBillDialog(formName))
        },
        details_detry_stock(detry_stock_id) {
            this.login_loading = true
            detry_bill_details_product_request(detry_stock_id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.fdetry_stock_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        to_approval(formName) {
            if(this.DetryBill.detry_product.length===0) {
                this.$message.error('未添加出库产品!')
                return
            }

            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    detry_bill_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDetryBillDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            detry_bill_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        get_stock_rate() {
            client_stock_rate_list_request({client_id: this.DetryBill.client_id})
                .then((s) => {
                    this.fAllClientStockRate = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        show_detry(detry_stock_id) {
            this.detryDialogVisible = true
            this.get_stock_rate()
            this.details_detry_stock(detry_stock_id)
        },
        exitDetryDialog() {
            this.detryDialogVisible = false
        },
        DetryBillWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    detry_bill_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        }
    },
    filters:{
        filter_rate: v=>{
            return v + '元/吨/次'
        },
        diy_weight(v) {
            return thousandBitSeparator(v) + " KG"
        },
        diy_tray_num(v) {
            return v + " 个"
        },
        diy_money(v) {
            return "￥" + thousandBitSeparator(fomatFloat(v)) + "元"
        }
    },
    props:{
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        detry_bill_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        AllClient: {
            type: Array,
            default() {
                return []
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        fAllSatff: Array,
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CommonTableComponent,
        CommonApprovalDialog,
        EditDetryStockComponent
    },
    watch:{
        detry_bill_details_data(newVal) {
            this.DetryBill = newVal
        },
    }
}
</script>

<style lang='less'>
.clientc {
    width: 100%;
}
.detry_date.el-date-editor.el-input {
    width: 100%;
}
.fieldsetc {
    margin-bottom: 20px;
    min-width: 95%;
    min-width: border-box;
}
.legendc {
    color: #921AFF;
}
</style>