import request from '../request'

//出库单列表
export const detry_bill_index_request = p => {
    return request({
        method:'GET',
        url:'detry_bill/index',
        params: p
    })
}

//添加出库单
export const detry_bill_add_request = d => {
    return request({
        method:'POST',
        url:'detry_bill/add',
        data: d
    })
}

//删除出库单
export const detry_bill_del_request = id => {
    return request({
        method:'DELETE',
        url:'detry_bill/del',
        data: {
            id: id
        }
    })
}

//修改出库单
export const detry_bill_edit_request = d => {
    return request({
        method:'PUT',
        url:'detry_bill/edit',
        data: d
    })
}

//获取出库单详情
export const detry_bill_details_request = id => {
    return request({
        method:'GET',
        url:'detry_bill/details',
        params: {
            id
        }
    })
}

//出库单中产品详情
export const detry_bill_details_product_request = product_id => {
    return request({
        method:'GET',
        url:'detry_bill/details_product',
        params: {
            product_id: product_id
        }
    })
}

//修改出库单中产品
export const detry_bill_edit_product_request = data => {
    return request({
        method:'PUT',
        url:'detry_bill/edit_product',
        data
    })
}

//删除出库单中产品
export const detry_bill_del_product_request = product_id => {
    return request({
        method:'DELETE',
        url:'detry_bill/del_product',
        data: {
            product_id: product_id
        }
    })
}

// 提交出库单
export const detry_bill_to_approval_request = d => {
    return request({
        method:'POST',
        url:'detry_bill/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看出库单审批记录
export const detry_bill_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'detry_bill/sel_approval',
        params: {
            id
        }
    })
}

// 审批出库单
export const detry_bill_approval_request = d => {
    return request({
        method:'PUT',
        url:'detry_bill/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回出库单
export const detry_bill_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'detry_bill/withdraw',
        data: {
            id: d.id
        }
    })
}