<template>
    <div v-loading.fullscreen.lock="loading">
        <detry-bill-search-bar-component
            add_auth="新增出库单"
            :AllClient="fAllClient"
            @search="get_detry_bill_index"
            @addTeam="addTeam"
        ></detry-bill-search-bar-component>
        <common-table-component
            details_auth="出库单详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_detry_bill"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_detry_bill_index"
        ></common-page-component>
        <detry-bill-edit-component
            :id="fid"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :AllClient="fAllClient"
            :detry_bill_details_data="fdetry_bill_details_data"
            :fAllSatff="fAllSatff"
            @exitDetryBillDialog="dialogExit"
            @search="get_detry_bill_index"
            @show_edit="show_edit"
            @refresh="refresh_details"
            @frefresh="frefresh"
        ></detry-bill-edit-component>
    </div>
</template>

<script>
import { detry_bill_index_request,detry_bill_details_request } from '@/network/WareHouse/DetryBill.js'
import { client_list_request,staff_list_request } from '@/network/list.js'

import DetryBillSearchBarComponent from '@/components/WareHouse/DetryBill/DetryBillSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import DetryBillEditComponent from '@/components/WareHouse/DetryBill/DetryBillEditComponent'

export default {
    name:'DetryBillView',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            cond: {
                state: '',
                name: '',
                box_num: '',
                car_num: '',
                client_id: '',
                product_name: '',
                entry_staff_id: '',
                detry_date: '',
                page: 1,
                limit: 10,
            },
            fAllClient: [],
            ftable_data: [],
            ftotal: 0,
            fdetry_bill_details_data: {},
            fAllSatff: [],
            fcol_arr: [
                {
                    prop: 'detry_date',
                    label: '出库日期',
                    minWidth: '120px',
                },
                {
                    prop: 'name',
                    label: '出库单号',
                    minWidth: '120px',
                },{
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },{
                    prop: 'box_num',
                    label: '集装箱号',
                    minWidth: '120px',
                },{
                    prop: 'car_num',
                    label: '车牌号',
                    minWidth: '120px',
                },{
                    prop: 'client_name',
                    label: '客户名称',
                    minWidth: '300px',
                },{
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },{
                    prop: 'entry_staff_name',
                    label: '录单员',
                    minWidth: '100px',
                }
            ],
            edit_auth: false,
            del_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{},
    methods:{
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_all_client() {
            client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllSatff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_detry_bill_index(param = {}) {
            this.loading = true
            this.cond.state = param.state ?? this.cond.state
            this.cond.name = param.name ?? this.cond.name
            this.cond.box_num = param.box_num ?? this.cond.box_num
            this.cond.car_num = param.car_num ?? this.cond.car_num
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.product_name = param.product_name ?? this.cond.product_name
            this.cond.entry_staff_id = param.entry_staff_id ?? this.cond.entry_staff_id
            this.cond.detry_date = param.detry_date ?? this.cond.detry_date
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            detry_bill_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_detry_bill(id) {
            this.fid = id
            this.fdialogFormVisible = true
            detry_bill_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fdetry_bill_details_data = s.result
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交出库单')
                    this.edit_auth = this.$_has('修改出库单')
                    this.del_auth = this.$_has('删除出库单')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回出库单')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批出库单')
                }
                this.sel_approval_auth = this.$_has('查看出库单审批记录')
            } else if(res.state === 2) {
                this.sel_approval_auth = this.$_has('查看出库单审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交出库单')
                    this.edit_auth = this.$_has('修改出库单')
                    this.del_auth = this.$_has('删除出库单')
                }
                this.sel_approval_auth = this.$_has('查看出库单审批记录')
            }
        },
        refresh_details(id) {
            this.details_detry_bill(id)
        },
        frefresh() {
            this.details_detry_bill(this.fid)
            this.get_detry_bill_index()
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_all_client()
        this.get_detry_bill_index()
        this.get_all_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        DetryBillSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        DetryBillEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>